<template>
    <div :id="`update-intercity-${nomenclatureData.id}`" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" :id="`myModalLabel2-${nomenclatureData.id}`">{{nomenclatureData.name}}</h4>
                    <button :id="`update_${nomenclatureData.id}_intercity_close_button`" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmit" class="form form-horizontal form-element">
                        
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <input v-model="nomenclatureName" type="text" class="form-control" placeholder="Name">
                            <p class="error" v-if="nomenclatureNameError">{{nomenclatureNameErrorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <label>Country</label>
                            <input type="text" class="form-control" placeholder="Name" :value="nomenclatureData.cities[0].country.name" disabled>
                        </div>
                        <div class="form-group">
                            <label>State</label>
                            <input type="text" class="form-control" placeholder="Name" :value="nomenclatureData.cities[0].state.name" disabled>
                        </div>
                        <div class="form-group">
                            <label>Select Cities</label>
                            <p class="error" v-if="selectedCitiesError">{{selectedCitiesErrorMessage}}</p>
                            <div class="demo-checkbox">
                                <div v-for="city in cityList" :key="city.id">
                                    <input type="checkbox" :id="`check-${nomenclatureData.id}-${city.id}`" class="chk-col-red" :value="city.id" v-model="selectedCities" :checked="city.isChecked"/>
                                    <label :for="`check-${nomenclatureData.id}-${city.id}`">{{city.name}}</label>
                                </div>
                            </div>
                        </div>
                        
                    </form>
                    
                </div>
                <div class="modal-footer">
                    <button v-if="isRoleAllowedDelete"  type="button" class="btn btn-info" @click="updateInterCityNomenclature">Update<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <button v-if="isRoleAllowedDelete" type="button" class="btn btn-outline btn-danger" @click="deleteNomenclature">DELETE NOMENCLATURE<span v-if="deleteLoading" class="spinner-border text-red"></span></button>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'UpdateNomenclatureInterCity',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)


    this.nomenclatureData.cities.forEach((city)=>{
        this.selectedCities.push(city.city_id);
    })
    this.loadCities(this.nomenclatureData.cities[0].state.id);
  },
  props: {
      nomenclatureData:{
          type: Object,
      }
  },
  computed: {
      determineChecked(cityFromAllCityList){
        if (this.nomenclatureData==null) return '';
        this.nomenclatureData.cities.forEach((nomenclatureCity)=>{
            if (nomenclatureCity.id==cityFromAllCityList.id) return 'checked';
        })
        return '';
      },
    isRoleAllowedDelete(){
        return repository.adminIsLord()
    },
  },
  data () {
    return {

      countryList: [],
      stateList: [],
      cityList: [],
      

      nomenclatureName: this.nomenclatureData.name,
      nomenclatureNameError: false,
      nomenclatureNameErrorMessage: '',

      selectedCountryID: 0,
      selectedCountryError: false,
      selectedCountryErrorMessage: '',

      selectedStateID: 0,
      selectedStateError: false,
      selectedStateErrorMessage: '',

      selectedCities: [],
      selectedCitiesError: false,
      selectedCitiesErrorMessage: '',
      
      deleteLoading: false,
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
    }
  },
  watch: {
      selectedCountryID: function(countryID){
          if(countryID<=0) return;
          this.resetStateData();
          this.resetCityData();
          this.loadStates(countryID);
      },
      selectedStateID: function(stateID){
          if(stateID<=0) return;
          this.resetCityData();
          this.loadCities(stateID);
      },
  },
  methods: {
    //   determineChecked(cityFromAllCityList){
    //     if (this.nomenclatureData==null) return '';
    //     console.log("nomen data is ", this.nomenclatureData)
    //     this.nomenclatureData.cities.forEach((nomenclatureCity)=>{
    //         if (nomenclatureCity.id==cityFromAllCityList.id) return 'checked';
    //     })
    //     return '';
    //   },
    async updateInterCityNomenclature () {
      if (this.loading) return
      this.resetData()
      const newInterCityNomenclatureValidation = this.validateNewInterCityNomenclature()
      if (!newInterCityNomenclatureValidation.success) return
      this.loading = true
      const data = {
        nomenclatureID: this.nomenclatureData.id  ,
        name: this.nomenclatureName,
        cityList: this.selectedCities
      }
      const response = await repository.regions.updateInterCityNomenclature(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Nomenclature Updated');
        repository.regions.loadInterCityNomenclatureList()
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewInterCityNomenclature () {
      const returnValue = new Object()
      if (!this.nomenclatureName) {
        this.nomenclatureNameError = true
        this.nomenclatureNameErrorMessage = 'Enter a valid name'
        returnValue.success = false
        return returnValue
      }
      if (!this.selectedCities.length) {
        this.selectedCitiesError = true
        this.selectedCitiesErrorMessage = 'Select cities for this region'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
        let closeButton = document.querySelector(`#update_${this.nomenclatureData.id}_intercity_close_button`);
        closeButton.click();
        swal(message, "", "success")
        
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.nomenclatureNameError=false;
      this.selectedCountryError=false;
      this.selectedCitiesError=false;
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    },



    async loadCountries() {
        const countryResponse = await repository.regions.fetchCountries();
        if (countryResponse.success){
            this.countryList = countryResponse.data;
            return;
        }
    },
    async loadStates(countryID) {
        const data = {
            countryID: countryID,
        }
        const stateResponse = await repository.regions.fetchStates(data);
        if (stateResponse.success){
            this.stateList = stateResponse.data;
            return;
        }
    },
    async loadCities(stateID) {
        const data = {
            stateID: stateID,
        }
        const cityResponse = await repository.regions.fetchCities(data, 'nomenclature-update', this.nomenclatureData.id);
        if (cityResponse.success){
            cityResponse.data.forEach((city)=>{
                city.isChecked = this.selectedCities.includes(city.id);
            });
            this.cityList = cityResponse.data;
            return;
        }
    },

    resetStateData() {
        this.selectedStateID = 0;
        this.stateList = [];
    },
    resetCityData() {
        this.selectedCities = [];
        this.cityList = [];
    },


    deleteNomenclature(){
        if (confirm('Are you sure you want to delete this nomenclature? This will delete all price list related to this nomenclature and this action cannot be reversed, do you want to proceed?')) {
            this.deleteLoading=true;
            repository.regions.deleteInterCityNomenclature(this.nomenclatureData.id);
        }
    },
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
.modal-content{
    overflow: scroll !important;
    height: 80% !important;
}
</style>

