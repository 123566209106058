<template>
     <!-- Main content -->
    <section class="content-wrapper">
        <section class="content-header">
            <h1>Nomenclatures</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><router-link :to="{path: '/'}">Home</router-link></li>
                <li class="breadcrumb-item active">Nomenclature</li>
            </ol>
        </section>
		<div class="row">
            <div class="col-12">
                <div class="row">
                    
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterCity</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-intercity">Add</button>
                                            <NewNomenclatureInterCity/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <div  v-for="interCityNomenclature in interCityNomenclatureList" :key="interCityNomenclature.id">
                                        <a  class="media media-single" href="#"   data-toggle="modal" :data-target="`#update-intercity-${interCityNomenclature.id}`">
                                            <span class="title">{{interCityNomenclature.name}}</span>
                                            <span class="badge badge-pill badge-secondary">{{ interCityNomenclature.count }}</span>
                                        </a>
                                        <UpdateNomenclatureInterCity :nomenclatureData="interCityNomenclature"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterState</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-interstate">Add</button>
                                            <NewNomenclatureInterState/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <div  v-for="interStateNomenclature in interStateNomenclatureList" :key="interStateNomenclature.id">
                                        <a class="media media-single" href="#"   data-toggle="modal" :data-target="`#update-interstate-${interStateNomenclature.id}`">
                                            <span class="title">{{interStateNomenclature.name}}</span>
                                            <span class="badge badge-pill badge-secondary">{{ interStateNomenclature.count }}</span>
                                        </a>
                                        <UpdateNomenclatureInterState :nomenclatureData="interStateNomenclature"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    
                    



                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterCountry</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-intercountry">Add</button>
                                            <NewNomenclatureInterCountry/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <div  v-for="interCountryNomenclature in interCountryNomenclatureList" :key="interCountryNomenclature.id">
                                        <a class="media media-single" href="#"   data-toggle="modal" :data-target="`#update-intercountry-${interCountryNomenclature.id}`">
                                            <span class="title">{{interCountryNomenclature.name}}</span>
                                            <span class="badge badge-pill badge-secondary">{{ interCountryNomenclature.count }}</span>
                                        </a>
                                        <UpdateNomenclatureInterCountry :nomenclatureData="interCountryNomenclature"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    
                    
                </div>
            </div>
		</div>
    </section>
</template>

<script>
import NewNomenclatureInterCity from '../../components/Nomenclature/NewNomenclatureInterCity'
import UpdateNomenclatureInterCity from '../../components/Nomenclature/UpdateNomenclatureInterCity'
import NewNomenclatureInterState from '../../components/Nomenclature/NewNomenclatureInterState'
import UpdateNomenclatureInterState from '../../components/Nomenclature/UpdateNomenclatureInterState'
import NewNomenclatureInterCountry from '../../components/Nomenclature/NewNomenclatureInterCountry'
import UpdateNomenclatureInterCountry from '../../components/Nomenclature/UpdateNomenclatureInterCountry'
import { mapGetters } from 'vuex';
import { repository } from '../../presenter'
export default {
    components:{
        NewNomenclatureInterCity,
        UpdateNomenclatureInterCity,
        NewNomenclatureInterState,
        UpdateNomenclatureInterState,
        NewNomenclatureInterCountry,
        UpdateNomenclatureInterCountry,
    },
    computed: {
        ...mapGetters('region',{
            interCityNomenclatureList: 'retrieveInterCityNomenclatures',
            interStateNomenclatureList: 'retrieveInterStateNomenclatures',
            interCountryNomenclatureList: 'retrieveInterCountryNomenclatures',
        })
    },
    data() {
        return{
            publicPath: process.env.BASE_URL,
        }
    },
    mounted() {
        const listJS = document.createElement('script')
        listJS.setAttribute('src', `${this.publicPath}assets/js/pages/list.js`)
        document.head.appendChild(listJS)

        this.loadInterCityNomenclatureList();
        this.loadInterStateNomenclatureList();
        this.loadInterCountryNomenclatureList();
    },
    methods: {
        loadInterCityNomenclatureList(){
            repository.regions.loadInterCityNomenclatureList();
        },
        loadInterStateNomenclatureList(){
            repository.regions.loadInterStateNomenclatureList();
        },
        loadInterCountryNomenclatureList(){
            repository.regions.loadInterCountryNomenclatureList();
        },
        
    }
}
</script>

