<template>
    <div id="add-intercountry" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">New InterCountry Nomenclature</h4>
                    <button ref="add_intercountry_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmit" class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <input v-model="nomenclatureName" type="text" class="form-control" placeholder="Name">
                            <p class="error" v-if="nomenclatureNameError">{{nomenclatureNameErrorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <label>Select Countries</label>
                            <p class="error" v-if="selectedCountriesError">{{selectedCountriesErrorMessage}}</p>
                            <div class="demo-checkbox">
                                <div v-for="country in countryList" :key="country.id">
                                    <input type="checkbox" :id="country.id" class="chk-col-red" :value="country.id" v-model="selectedCountries"/>
                                    <label :for="country.id">{{country.name}}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveNewInterCountryNomenclature">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'NewNomenclatureInterCountry',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)

    this.loadCountries();
  },
  data () {
    return {
      countryList: [],

      nomenclatureName: '',
      nomenclatureNameError: false,
      nomenclatureNameErrorMessage: '',

      selectedCountries: [],
      selectedCountriesError: false,
      selectedCountriesErrorMessage: '',
      
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    async saveNewInterCountryNomenclature () {
      if (this.loading) return
      this.resetData()
      const newInterCountryNomenclatureValidation = this.validateNewInterCountryNomenclature()
      if (!newInterCountryNomenclatureValidation.success) return
      this.loading = true
      const data = {
        name: this.nomenclatureName,
        countryList: this.selectedCountries
      }
      const response = await repository.regions.createNewInterCountryNomenclature(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Nomenclature Created');
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewInterCountryNomenclature () {
      const returnValue = new Object()
      if (!this.nomenclatureName) {
        this.nomenclatureNameError = true
        this.nomenclatureNameErrorMessage = 'Enter a valid name'
        returnValue.success = false
        return returnValue
      }
      if (!this.selectedCountries.length) {
        this.selectedCountriesError = true
        this.selectedCountriesErrorMessage = 'Select countries for this region'
        returnValue.success = false
        return returnValue
      }

      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.add_intercountry_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.nomenclatureNameError=false;
      this.selectedCountryError=false;
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    },


    async loadCountries() {
        const countryResponse = await repository.regions.fetchCountries(null, 'nomenclature');
        if (countryResponse.success){
            this.countryList = countryResponse.data;
            return;
        }
    },

  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

