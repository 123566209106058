<template>
    <div :id="`update-intercountry-${nomenclatureData.id}`" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" :id="`myModalLabel2-${nomenclatureData.id}`">{{nomenclatureData.name}}</h4>
                    <button :id="`update_${nomenclatureData.id}_intercity_close_button`" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmit" class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <input v-model="nomenclatureName" type="text" class="form-control" placeholder="Name">
                            <p class="error" v-if="nomenclatureNameError">{{nomenclatureNameErrorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <label>Select Countries</label>
                            <p class="error" v-if="selectedCountriesError">{{selectedCountriesErrorMessage}}</p>
                            <div class="demo-checkbox">
                                <div v-for="country in countryList" :key="country.id">
                                    <input type="checkbox" :id="`check-${nomenclatureData.id}-${country.id}`" class="chk-col-red" :value="country.id" v-model="selectedCountries" :checked="country.isChecked"/>
                                    <label :for="`check-${nomenclatureData.id}-${country.id}`">{{country.name}}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button v-if="isRoleAllowedDelete"  type="button" class="btn btn-info" @click="updateInterCountryNomenclature">Update<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <button v-if="isRoleAllowedDelete" type="button" class="btn btn-outline btn-danger" @click="deleteNomenclature">DELETE NOMENCLATURE<span v-if="deleteLoading" class="spinner-border text-red"></span></button>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'UpdateNomenclatureInterCountry',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
    document.head.appendChild(sweetAlert)


    this.nomenclatureData.countries.forEach((country)=>{
        this.selectedCountries.push(country.country_id);
    })
    this.loadCountries();
    // this.loadStates(this.nomenclatureData.states[0].country.id);
  },
  props: {
      nomenclatureData:{
          type: Object,
      }
  },
  data () {
    return {
    
      publicPath: process.env.BASE_URL,
      countryList: [],
      

      nomenclatureName: this.nomenclatureData.name,
      nomenclatureNameError: false,
      nomenclatureNameErrorMessage: '',

    //   selectedCountryID: 0,
    //   selectedCountryError: false,
    //   selectedCountryErrorMessage: '',

      selectedCountries: [],
      selectedCountriesError: false,
      selectedCountriesErrorMessage: '',
      
      deleteLoading: false,
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  watch: {
    //   selectedCountryID: function(countryID){
    //       if(countryID<=0) return;
    //       this.resetStateData();
    //       this.loadStates(countryID);
    //   },
  },
  computed: {
    isRoleAllowedDelete(){
        return repository.adminIsLord()
    },
  },
  methods: {
    //   determineChecked(cityFromAllCityList){
    //     if (this.nomenclatureData==null) return '';
    //     console.log("nomen data is ", this.nomenclatureData)
    //     this.nomenclatureData.cities.forEach((nomenclatureCity)=>{
    //         if (nomenclatureCity.id==cityFromAllCityList.id) return 'checked';
    //     })
    //     return '';
    //   },
    async updateInterCountryNomenclature () {
      if (this.loading) return
      this.resetData()
      const newInterCountryNomenclatureValidation = this.validateNewInterCountryNomenclature()
      if (!newInterCountryNomenclatureValidation.success) return
      this.loading = true
      const data = {
        nomenclatureID: this.nomenclatureData.id  ,
        name: this.nomenclatureName,
        countryList: this.selectedCountries
      }
      const response = await repository.regions.updateInterCountryNomenclature(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Nomenclature Updated');
        repository.regions.loadInterCountryNomenclatureList();
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewInterCountryNomenclature () {
      const returnValue = new Object()
      if (!this.nomenclatureName) {
        this.nomenclatureNameError = true
        this.nomenclatureNameErrorMessage = 'Enter a valid name'
        returnValue.success = false
        return returnValue
      }
      if (!this.selectedCountries.length) {
        this.selectedCountriesError = true
        this.selectedCountriesErrorMessage = 'Select Countries for this region'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
        let closeButton = document.querySelector(`#update_${this.nomenclatureData.id}_intercity_close_button`);
        closeButton.click();
        swal(message, "", "success")
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.nomenclatureNameError=false;
    //   this.selectedCountryError=false;
      this.selectedCountriesError=false;
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    },



    // async loadCountries() {
    //     const countryResponse = await repository.regions.fetchCountries();
    //     if (countryResponse.success){
    //         this.countryList = countryResponse.data;
    //         return;
    //     }
    // },
    async loadCountries() {
        const countryResponse = await repository.regions.fetchCountries(null, 'nomenclature-update', this.nomenclatureData.id);
        if (countryResponse.success){
            countryResponse.data.forEach((country)=>{
                country.isChecked = this.selectedCountries.includes(country.id);
            });
            this.countryList = countryResponse.data;
            return;
        }
    },

    deleteNomenclature(){
        if (confirm('Are you sure you want to delete this nomenclature? This will delete all price list related to this nomenclature and this action cannot be reversed, do you want to proceed?')) {
            this.deleteLoading=true;
            repository.regions.deleteInterCountryNomenclature(this.nomenclatureData.id);
        }
    },

  
    resetCountryData() {
        this.selectedCountries = [];
        this.countryList = [];
    },
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

