<template>
    <div :id="`update-interstate-${nomenclatureData.id}`" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" :id="`myModalLabel2-${nomenclatureData.id}`">{{nomenclatureData.name}}</h4>
                    <button :id="`update_${nomenclatureData.id}_intercity_close_button`" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmit" class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <input v-model="nomenclatureName" type="text" class="form-control" placeholder="Name">
                            <p class="error" v-if="nomenclatureNameError">{{nomenclatureNameErrorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <label>Country</label>
                            <input type="text" class="form-control" placeholder="Name" :value="nomenclatureData.states[0].country.name">
                        </div>
                        <div class="form-group">
                            <label>Select States</label>
                            <p class="error" v-if="selectedStatesError">{{selectedStatesErrorMessage}}</p>
                            <div class="demo-checkbox">
                                <div v-for="state in stateList" :key="state.id">
                                    <input type="checkbox" :id="`check-${nomenclatureData.id}-${state.id}`" class="chk-col-red" :value="state.id" v-model="selectedStates" :checked="state.isChecked"/>
                                    <label :for="`check-${nomenclatureData.id}-${state.id}`">{{state.name}}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button v-if="isRoleAllowedDelete" type="button" class="btn btn-info" @click="updateInterStateNomenclature">Update<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <button v-if="isRoleAllowedDelete" type="button" class="btn btn-outline btn-danger" @click="deleteNomenclature">DELETE NOMENCLATURE<span v-if="deleteLoading" class="spinner-border text-red"></span></button>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'UpdateNomenclatureInterState',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)

    console.log("in mounted, nomenclature data states is ", this.nomenclatureData.states)


    this.nomenclatureData.states.forEach((state)=>{
        this.selectedStates.push(state.state_id);
    })
    this.loadStates(this.nomenclatureData.states[0].country.id);
  },
  props: {
      nomenclatureData:{
          type: Object,
      }
  },
  data () {
    return {

      countryList: [],
      stateList: [],
      

      nomenclatureName: this.nomenclatureData.name,
      nomenclatureNameError: false,
      nomenclatureNameErrorMessage: '',

      selectedCountryID: 0,
      selectedCountryError: false,
      selectedCountryErrorMessage: '',

      selectedStates: [],
      selectedStatesError: false,
      selectedStatesErrorMessage: '',
      
      deleteLoading: false,
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  watch: {
      selectedCountryID: function(countryID){
          if(countryID<=0) return;
          this.resetStateData();
          this.loadStates(countryID);
      },
  },
  computed: {
    isRoleAllowedDelete(){
        return repository.adminIsLord()
    },
  },
  methods: {
    async updateInterStateNomenclature () {
      if (this.loading) return
      this.resetData()
      const newInterStateNomenclatureValidation = this.validateNewInterStateNomenclature()
      if (!newInterStateNomenclatureValidation.success) return
      this.loading = true
      const data = {
        nomenclatureID: this.nomenclatureData.id  ,
        name: this.nomenclatureName,
        stateList: this.selectedStates
      }
      const response = await repository.regions.updateInterStateNomenclature(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Nomenclature Updated');
        repository.regions.loadInterStateNomenclatureList()
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewInterStateNomenclature () {
      const returnValue = new Object()
      if (!this.nomenclatureName) {
        this.nomenclatureNameError = true
        this.nomenclatureNameErrorMessage = 'Enter a valid name'
        returnValue.success = false
        return returnValue
      }
      if (!this.selectedStates.length) {
        this.selectedStatesError = true
        this.selectedStatesErrorMessage = 'Select States for this region'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
        let closeButton = document.querySelector(`#update_${this.nomenclatureData.id}_intercity_close_button`);
        closeButton.click();
        swal(message, "", "success")
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.nomenclatureNameError=false;
      this.selectedCountryError=false;
      this.selectedStatesError=false;
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    },



    async loadCountries() {
        const countryResponse = await repository.regions.fetchCountries();
        if (countryResponse.success){
            this.countryList = countryResponse.data;
            return;
        }
    },
    async loadStates(countryID) {
        const data = {
            countryID : countryID,
        }
        const stateResponse = await repository.regions.fetchStates(data, 'nomenclature-update', this.nomenclatureData.id);
        if (stateResponse.success){
            stateResponse.data.forEach((state)=>{
                state.isChecked = this.selectedStates.includes(state.id);
            });
            this.stateList = stateResponse.data;
            return;
        }
    },

    deleteNomenclature(){
        if (confirm('Are you sure you want to delete this nomenclature? This will delete all price list related to this nomenclature and this action cannot be reversed, do you want to proceed?')) {
            this.deleteLoading=true;
            repository.regions.deleteInterStateNomenclature(this.nomenclatureData.id);
        }
    },

  
    resetStateData() {
        this.selectedStates = [];
        this.stateList = [];
    },
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

