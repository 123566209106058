<template>
    <div id="add-intercity" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">New InterCity Nomenclature</h4>
                    <button ref="add_intercity_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmit" class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <input v-model="nomenclatureName" type="text" class="form-control" placeholder="Name">
                            <p class="error" v-if="nomenclatureNameError">{{nomenclatureNameErrorMessage}}</p>
                        </div>
                        <div class="form-group">
                            <label>Select Country</label>
                            <select v-model="selectedCountryID" class="form-control">
                                <option value="">Select Country</option>
                                <option v-for="country in countryList" :key="country.id" :value="country.id">{{country.name}}</option>
                            </select>
                            <p class="error" v-if="selectedCountryError">{{selectedCountryErrorMessage}}</p>
                        </div>
                        <div v-if="stateList.length" class="form-group">
                            <label>Select State</label>
                            <select v-model="selectedStateID" class="form-control">
                                <option value="">Select State</option>
                                <option v-for="state in stateList" :key="state.id" :value="state.id">{{state.name}}</option>
                            </select>
                            <p class="error" v-if="selectedStateError">{{selectedStateErrorMessage}}</p>
                        </div>
                        <div v-if="cityList.length" class="form-group">
                            <label>Select Cities</label>
                            <p class="error" v-if="selectedCitiesError">{{selectedCitiesErrorMessage}}</p>
                            <div class="demo-checkbox">
                                <div v-for="city in cityList" :key="city.id">
                                    <input type="checkbox" :id="city.id" class="chk-col-red" :value="city.id" v-model="selectedCities"/>
                                    <label :for="city.id">{{city.name}}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveNewInterCityNomenclature">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'NewNomenclatureInterCity',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)

    this.loadCountries();
  },
  data () {
    return {
      countryList: [],
      stateList: [],
      cityList: [],
      

      nomenclatureName: '',
      nomenclatureNameError: false,
      nomenclatureNameErrorMessage: '',

      selectedCountryID: 0,
      selectedCountryError: false,
      selectedCountryErrorMessage: '',

      selectedStateID: 0,
      selectedStateError: false,
      selectedStateErrorMessage: '',

      selectedCities: [],
      selectedCitiesError: false,
      selectedCitiesErrorMessage: '',
      
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  watch: {
      selectedCountryID: function(countryID){
          if(countryID<=0) return;
          this.resetStateData();
          this.resetCityData();
          this.loadStates(countryID);
      },
      selectedStateID: function(stateID){
          if(stateID<=0) return;
          this.resetCityData();
          this.loadCities(stateID);
      },
  },
  methods: {
    async saveNewInterCityNomenclature () {
      if (this.loading) return
      this.resetData()
      const newInterCityNomenclatureValidation = this.validateNewInterCityNomenclature()
      if (!newInterCityNomenclatureValidation.success) return
      this.loading = true
      const data = {
        name: this.nomenclatureName,
        countryID: this.selectedCountryID,
        stateID: this.selectedStateID,
        cityList: this.selectedCities
      }
      const response = await repository.regions.createNewInterCityNomenclature(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Nomenclature Created');
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewInterCityNomenclature () {
      const returnValue = new Object()
      if (!this.nomenclatureName) {
        this.nomenclatureNameError = true
        this.nomenclatureNameErrorMessage = 'Enter a valid name'
        returnValue.success = false
        return returnValue
      }
      if (!this.selectedCountryID) {
        this.selectedCountryError = true
        this.selectedCountryErrorMessage = 'Select a country'
        returnValue.success = false
        return returnValue
      }
      if (!this.selectedStateID) {
        this.selectedStateError = true
        this.selectedStateErrorMessage = 'Select a state'
        returnValue.success = false
        return returnValue
      }
      if (!this.selectedCities.length) {
        this.selectedCitiesError = true
        this.selectedCitiesErrorMessage = 'Select cities for this region'
        returnValue.success = false
        return returnValue
      }

      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.add_intercity_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.nomenclatureNameError=false;
      this.selectedCountryError=false;
      this.selectedStateError=false;
      this.selectedCitiesError=false;
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    },



    async loadCountries() {
        const countryResponse = await repository.regions.fetchCountries();
        if (countryResponse.success){
            this.countryList = countryResponse.data;
            return;
        }
    },
    async loadStates(countryID) {
        const data = {
            countryID: countryID,
        }
        const stateResponse = await repository.regions.fetchStates(data);
        if (stateResponse.success){
            this.stateList = stateResponse.data;
            return;
        }
    },
    async loadCities(stateID) {
        const data = {
            stateID: stateID,
        }
        const cityResponse = await repository.regions.fetchCities(data, 'nomenclature');
        if (cityResponse.success){
            this.cityList = cityResponse.data;
            return;
        }
    },

    resetStateData() {
        this.selectedStateID = 0;
        this.stateList = [];
    },
    resetCityData() {
        this.selectedCities = [];
        this.cityList = [];
    },
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

